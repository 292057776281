<template>
  <div>
    <b-card v-if="hasPermission('create_job_noc')">
      <validation-observer ref="nocFormValidation">
        <b-form @submit.prevent="validateAndSubmit()">
          <b-row class="mb-2">
            <b-col md="4">
              <b-form-group
                label-for="Job Location"
                v-slot="{ ariaDescribedby }"
                label="Job Location"
              >
                <b-form-radio-group
                  id="job_location"
                  v-model="nocForm.job_abroad"
                  :aria-describedby="ariaDescribedby"
                  class="text-black"
                >
                  <b-form-radio v-model="nocForm.job_abroad" :value="false">
                    InLand
                  </b-form-radio>
                  <b-form-radio v-model="nocForm.job_abroad" :value="true">
                    Abroad
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label-for="noc-cnic">
                <label>CNIC</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="CNIC"
                  rules="required"
                >
                  <b-form-input
                    id="noc-cnic"
                    v-model="nocForm.cnic"
                    disabled
                    :state="errors.length ? false : null"
                    placeholder="CNIC"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="noc-name">
                <label>Name</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="noc-name"
                    v-model="nocForm.name"
                    disabled
                    :state="errors.length ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="noc-contact_no">
                <label>Mobile</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Mobile"
                  rules="required"
                >
                  <b-form-input
                    id="noc-contact_no"
                    v-model="nocForm.contact_no"
                    disabled
                    :state="errors.length ? false : null"
                    placeholder="Mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label-for="date-of-birth">
                <label>Date of birth <span class="text-danger">*</span></label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Date of birth"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="date-of-birth"
                    v-model="nocForm.dob"
                    :state="errors.length ? false : null"
                    type="date"
                    placeholder="Date of Birth"
                  /> -->
                  <b-form-datepicker
                    id="date-of-birth"
                    v-model="nocForm.dob"
                    :state="errors.length ? false : null"
                    variant="primary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="noc-designation">
                <label>Designation <span class="text-danger">*</span></label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Designation"
                  :rules="{
                    required,
                    regex: /^[a-zA-Z\s]+$/,
                  }"
                >
                  <b-form-input
                    id="noc-designation"
                    v-model="nocForm.designation"
                    :state="errors.length ? false : null"
                    placeholder="Designation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="noc-organization">
                <label>Organization <span class="text-danger">*</span></label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Organization"
                  :rules="{
                    required,
                    regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()_+=-]*$/,
                  }"
                >
                  <b-form-input
                    id="noc-organization"
                    v-model="nocForm.organization"
                    :state="errors.length ? false : null"
                    placeholder="Organization"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="struck-of-duty-date">
                <label
                  >Struck off duty date
                  <span class="text-danger">*</span></label
                >
                <validation-provider
                  v-slot="{ errors }"
                  name="Struck off duty date"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="struck-of-duty-date"
                    v-model="nocForm.struck_of_duty_date"
                    :state="errors.length ? false : null"
                    type="date"
                    placeholder="Struck of duty date"
                  /> -->
                  <b-form-datepicker
                    id="struck-of-duty-date"
                    v-model="nocForm.struck_of_duty_date"
                    :state="errors.length ? false : null"
                    :max="new Date().toISOString().split('T')[0]"
                    variant="primary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="noc-date-of-joining">
                <label
                  >Date of Joining (New Org)
                  <span class="text-danger">*</span></label
                >
                <validation-provider
                  v-slot="{ errors }"
                  name="Date of Joining"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="noc-date-of-joining-noc"
                    v-model="nocForm.date_of_joining"
                    :state="errors.length ? false : null"
                    type="date"
                    placeholder="Date of Joining"
                  /> -->
                  <b-form-datepicker
                    id="noc-date-of-joining-noc"
                    v-model="nocForm.date_of_joining"
                    :state="errors.length ? false : null"
                    :min="new Date().toISOString().split('T')[0]"
                    variant="primary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="email">
                <label>Email <span class="text-danger">*</span></label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="nocForm.email"
                    :state="errors.length ? false : null"
                    type="email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="Served in any Government/ Army sponsored organization"
                v-slot="{ ariaDescribedby }"
              >
                <label
                  >Served in any Government/ Army sponsored organization</label
                >
                <b-form-radio-group
                  id="service_in_govt_or_army"
                  v-model="nocForm.service_in_govt_or_army"
                  :aria-describedby="ariaDescribedby"
                  class="text-black"
                >
                  <b-form-radio
                    v-model="nocForm.service_in_govt_or_army"
                    :value="true"
                  >
                    Yes
                  </b-form-radio>
                  <b-form-radio
                    v-model="nocForm.service_in_govt_or_army"
                    :value="false"
                  >
                    No
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="Offer Letter Received"
                v-slot="{ ariaDescribedby }"
              >
                <label>Offer Letter Received </label>
                <b-form-radio-group
                  id="OfferLetterReceived"
                  v-model="nocForm.offer_letter_received"
                  :aria-describedby="ariaDescribedby"
                  class="text-black"
                >
                  <b-form-radio
                    v-model="nocForm.offer_letter_received"
                    :value="true"
                  >
                    Yes
                  </b-form-radio>
                  <b-form-radio
                    v-model="nocForm.offer_letter_received"
                    :value="false"
                  >
                    No
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="Previous request for NOCs"
                v-slot="{ ariaDescribedby }"
              >
                <label
                  >Previous request for NOCs
                  <span class="text-danger">*</span></label
                >
                <b-form-radio-group
                  id="previousRequestForNoc"
                  v-model="nocForm.previous_request_for_noc"
                  :aria-describedby="ariaDescribedby"
                  class="text-black"
                >
                  <b-form-radio
                    v-model="nocForm.previous_request_for_noc"
                    :value="true"
                  >
                    Yes
                  </b-form-radio>
                  <b-form-radio
                    v-model="nocForm.previous_request_for_noc"
                    :value="false"
                  >
                    No
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <!-- cascade dropdowns for countries, states and cities -->

            <b-col md="4">
              <b-form-group label-for="country">
                <label>Country <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  :rules="{ required }"
                >
                  <v-select
                    :options="countries"
                    v-model="selectedCountry"
                    label="name"
                    @input="selectCountry"
                    :disabled="!nocForm.job_abroad"
                    placeholder="Select a country"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="state">
                <template #label>
                  State <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="State"
                  :rules="{ required }"
                >
                  <v-select
                    :options="states"
                    v-model="selectedState"
                    label="name"
                    @input="selectState"
                    placeholder="Select a state"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="city">
                <template #label>
                  City <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  :rules="{ required }"
                >
                  <v-select
                    :options="cities"
                    v-model="selectedCity"
                    label="name"
                    @input="selectCity"
                    placeholder="Select a city"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- cascade dropdowns for countries, states and cities end -->

            <b-col md="4">
              <b-form-group label-for="duration_of_contract">
                <label
                  >Duration of contract (Months)
                  <span class="text-danger">*</span></label
                >
                <validation-provider
                  v-slot="{ errors }"
                  name="Duration of Contract"
                  rules="required|integer"
                >
                  <b-form-input
                    id="duration_of_contract"
                    v-model="nocForm.duration_of_contract"
                    :state="errors.length ? false : null"
                    type="number"
                    placeholder="Duration of contact"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="nocForm.job_abroad">
              <b-form-group
                label-for="Job Type"
                v-slot="{ ariaDescribedby }"
                label="Job Type"
                class="mt-75"
              >
                <b-form-radio-group
                  id="job_type"
                  v-model="nocForm.is_govt_job"
                  :aria-describedby="ariaDescribedby"
                  class="text-black"
                >
                  <b-form-radio v-model="nocForm.is_govt_job" :value="true">
                    Govt / Semi Govt
                  </b-form-radio>
                  <b-form-radio v-model="nocForm.is_govt_job" :value="false">
                    Private
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="nocForm.job_abroad">
              <b-form-group label-for="address_of_org">
                <label
                  >Company/Org Address <span class="text-danger">*</span></label
                >
                <validation-provider
                  v-slot="{ errors }"
                  name="Company/Org Address"
                  rules="required"
                >
                  <b-form-input
                    id="address_of_org"
                    v-model="nocForm.address_of_org"
                    :state="errors.length ? false : null"
                    type="text"
                    placeholder="Company/Org Address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="nocForm.job_abroad">
              <b-form-group label-for="own_address_during_emp">
                <label
                  >Own address during employment
                  <span class="text-danger">*</span></label
                >
                <validation-provider
                  v-slot="{ errors }"
                  name="Own address during employment"
                  rules="required"
                >
                  <b-form-input
                    id="own_address_during_emp"
                    v-model="nocForm.own_address_during_emp"
                    :state="errors.length ? false : null"
                    type="text"
                    placeholder="Own address during employment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="nocForm.job_abroad">
              <b-form-group label-for="sponsored_by">
                <label>Sponsored by <span class="text-danger">*</span></label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Sponsored by"
                  :rules="{
                    required,
                    regex: /^[a-zA-Z\s]+$/,
                  }"
                >
                  <b-form-input
                    id="sponsored_by"
                    v-model="nocForm.sponsored_by"
                    :state="errors.length ? false : null"
                    type="text"
                    placeholder="Sponsored by"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1 float-right"
          >
            Submit NOC
          </b-button>
        </b-form>
      </validation-observer>
      <p class="text-danger">
        <span class="font-weight-bold">Note: </span> Please email your job offer
        letter at caffairs00@outlook.com
      </p>
    </b-card>
    <b-card>
      <b-row
        class="mt-2 mb-2"
        style="border: 1px solid #ccc; border-radius: 5px; padding: 10px"
        v-if="hasRole('op') || hasRole('su') || hasRole('op_admin') || hasRole('re-emp_noc_op') || hasRole('re-emp_noc_admin')"
      >
        <b-col md="2">
          <b-form-group>
            <label> CNIC </label>
            <b-form-input
              id="cnic"
              v-model="cnic"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="CNIC"
              class="text-black"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group>
            <label> Name </label>
            <b-form-input
              id="Name"
              v-model="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Name"
              class="text-black"
            />
          </b-form-group>
        </b-col>
        <b-col class="text-right mb-1" style="margin: auto">
          <div style="display: flex; justify-content: flex-end">
            <b-button
              variant="primary"
              pill
              style="margin-right: 2%"
              @click="searchNocViaSearchButton"
            >
              <feather-icon icon="SearchIcon" class="mr-50" />
              Search
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-card-body class="mt-2">
        <b-table
          responsive="sm"
          :fields="fields_noc"
          :paginated="true"
          :items="jobs"
          details-td-class="m-0 p-0"
          :tbody-tr-class="rowClass"
          sticky-header="700px"
          small
          class="text-black"
        >
          <template #cell(is_received)="row">
            <span>
              {{ row.item.is_received ? "Yes" : "No" }}
            </span>
          </template>

          <template #cell(status)="row">
            <span>
              {{ getNocStatusByValue(row.item.status) }}
            </span>
          </template>

          <template #cell(manage)="row">
            <b-button
              variant="success"
              pill
              style="margin-right: 2%; margin-left: 2%"
              size="sm"
              @click="approveSelectedNoc(row.item)"
              v-if="
                !row.item.approved_for_job_noc &&
                hasPermission('update_job_noc')
              "
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              Approve
            </b-button>
            <b-button
              v-if="row.item.approved_for_job_noc"
              variant="info"
              pill
              size="sm"
              class="ml-25"
              @click="downloadNoc(row.item)"
            >
              Download NOC</b-button
            >
          </template>
        </b-table>

        <b-row>
          <b-col md="8">
            <b-pagination
              v-model="currentPageNoc"
              :total-rows="totalDataLengthNoc"
              :per-page="per_page_noc"
            />
          </b-col>
          <b-col md="4">
            <div style="float: right">
              <h5 style="margin: 0; display: inline" class="text-primary">
                Count:
              </h5>
              <h5 style="margin: 0; display: inline" align="right">
                <strong>
                  {{ totalDataLengthNoc }}
                </strong>
              </h5>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import util from "@/util.js";
import { heightTransition } from "@core/mixins/ui/transition";
import { getAllCountries } from "countries-and-timezones";
import { Country, State, City } from "country-state-city";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    VueSelectPaginated,
    VuePerfectScrollbar,
    draggable,
    Country,
    State,
    City,
  },
  mixins: [util, heightTransition],

  data() {
    return {
      name: null,
      cnic: null,
      totalDataLengthNoc: 0,
      per_page_noc: 0,
      currentPageNoc: 1,

      countries: [],
      states: [],
      cities: [],
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      jobs: [],
      nocForm: {
        cnic: "",
        name: "",
        struck_of_duty_date: "",
        designation: "",
        organization: "",
        date_of_joining: "",
        contact_no: "",
        dob: "",
        offer_letter_received: true,
        service_in_govt_or_army: false,
        job_abroad: false,
        city_of_employment: null,
        country_of_employment: null,
        email: null,
        previous_request_for_noc: false,
        duration_of_contract: null,
        is_govt_job: false,
        address_of_org: null,
        own_address_during_emp: null,
        sponsored_by: null,
      },
      show: false,

      fields_noc: [
        { key: "cnic", label: "CNIC", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "status", label: "status", sortable: true },
        { key: "is_received", label: "Received", sortable: true },
        { key: "contact_no", label: "Contact", sortable: true },
        { key: "created_at", label: "Request Date", sortable: true },
        { key: "manage", label: "Manage" },
      ],
      required,
    };
  },
  async mounted() {
    await this.loadCountries();
    await this.getNocData();
    this.selectedCountry = { code: "PK", name: "Pakistan" };
    this.selectCountry(this.selectedCountry);
    const userData = await this.getLoggedInUser;
    this.nocForm.cnic = userData.cnic;
    this.nocForm.name = userData.name;
    this.nocForm.contact_no = userData.mobile;
  },
  methods: {
    ...mapActions({
      submitNocRequest: "appData/submitNocRequest",
      getJobNocs: "appData/getJobNocs",
      getpdfJobNoc: "appData/getpdfJobNoc",
      partialUpdateJobNoc: "appData/partialUpdateJobNoc",
    }),

    async searchNocViaSearchButton() {
      this.currentPageNoc = 1;
      await this.searchNocWithFilters();
    },

    async searchNocWithFilters() {
      let payload = {};
      payload["pageNumber"] = this.currentPageNoc;
      if (this.name !== null) {
        payload["name"] = this.name;
      }
      if (this.cnic !== null) {
        payload["cnic"] = this.cnic;
      }
      await this.getNocData(payload);
    },

    async getNocData(searchParams = {}) {
      try {
        this.show = true;
        const res = await this.getJobNocs(searchParams);
        this.jobs = res.data.results;
        this.per_page_noc = res.data.per_page;
        this.totalDataLengthNoc = res.data.count;
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    validateDateOfJoining() {
      const joiningDate = new Date(this.nocForm.date_of_joining);
      const struckOffDate = new Date(this.nocForm.struck_of_duty_date);
      if (joiningDate <= struckOffDate) {
        console.log("Here");
        this.$refs.nocFormValidation.setErrors({
          "Date of Joining": ["Date of Joining must be after struck off date"],
        });
        return false;
      }
      return true;
    },
    async validateAndSubmit() {
      if (!this.validateDateOfJoining()) {
        return;
      }
      const isValid = await this.$refs.nocFormValidation.validate();
      if (isValid) {
        await this.submitNocForm();
        await this.getNocData();

        this.$refs.nocFormValidation.reset();
        this.reset();
      }
    },

    async submitNocForm() {
      this.show = true;
      try {
        const res = await this.submitNocRequest({
          cnic: this.nocForm.cnic,
          name: this.nocForm.name,
          struck_of_duty_date: this.nocForm.struck_of_duty_date,
          designation: this.nocForm.designation,
          organization: this.nocForm.organization,
          date_of_joining: this.nocForm.date_of_joining,
          contact_no: this.nocForm.contact_no,
          dob: this.nocForm.dob,
          offer_letter_received: this.nocForm.offer_letter_received,
          service_in_govt_or_army: this.nocForm.service_in_govt_or_army,
          job_abroad: this.nocForm.job_abroad,
          city_of_employment: this.selectedCity.name,
          country_of_employment: this.selectedCountry.name,
          state_of_employment: this.selectedState.name,
          email: this.nocForm.email,
          previous_request_for_noc: this.nocForm.previous_request_for_noc,
          duration_of_contract: this.nocForm.duration_of_contract,
          is_govt_job: this.nocForm.is_govt_job,
          address_of_org: this.nocForm.address_of_org,
          own_address_during_emp: this.nocForm.own_address_during_emp,
          sponsored_by: this.nocForm.sponsored_by,
          updated_by: this.getLoggedInUser.id,
          created_by: this.getLoggedInUser.id,
        });

        if (res.status === 201) {
          this.displaySuccess("Your request has successfully been submitted");
          this.show = false;
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    loadCountries() {
      const countries = getAllCountries();
      console.log("Fetched countries:", countries);
      this.countries = Object.values(countries).map((country) => ({
        name: country.name,
        code: country.id,
      }));
      console.log("Processed countries:", this.countries);
    },
    selectCountry(country) {
      this.selectedCountry = country;
      console.log("Selected country:", country);
      this.states = State.getStatesOfCountry(country.code);
      console.log("Fetched states:", this.states);
      this.cities = [];
      this.selectedState = null;
      this.selectedCity = null;
    },
    selectState(state) {
      this.selectedState = state;
      console.log("Selected state:", state);
      this.cities = City.getCitiesOfState(
        this.selectedCountry.code,
        state.isoCode
      );
      console.log("Fetched cities:", this.cities);
      this.selectedCity = null;
    },
    selectCity(city) {
      this.selectedCity = city;
      console.log("Selected city:", city);
    },

    async approveSelectedNoc(noc) {
      try {
        this.show = true;
        {
          const res = await this.partialUpdateJobNoc({
            id: noc.id,
            approved_for_job_noc: true,
          });
          if (res.status === 200) {
            this.displaySuccess("Noc has been approved");
          }
          await this.getNocData();
        }

        this.show = false;
      } catch (error) {
        this.displayError(error);
      }
    },
    downloadNoc(item) {
      this.getpdfJobNoc(item.id)
        .then((response) => {
          const pdfContent = atob(response.data.pdf_content);
          const pdfBlob = new Blob([pdfContent], { type: "application/pdf" });
          const url = window.URL.createObjectURL(pdfBlob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", response.data.file_name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // const url = window.URL.createObjectURL(new Blob([response.data.pdf_content]));
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", response.data.file_name);
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    },

    async reset() {
      (this.selectedCity = null),
        (this.selectedCountry = { code: "PK", name: "Pakistan" }),
        (this.selectedState = null),
        (this.nocForm = {
          cnic: this.getLoggedInUser.cnic,
          name: this.getLoggedInUser.name,
          contact_no: this.getLoggedInUser.mobile,
          struck_of_duty_date: "",
          designation: "",
          organization: "",
          date_of_joining: "",
          dob: "",
          offer_letter_received: true,
          service_in_govt_or_army: false,
          job_abroad: false,
          city_of_employment: null,
          country_of_employment: null,
          email: null,
          previous_request_for_noc: false,
          duration_of_contract: null,
          is_govt_job: false,
          address_of_org: null,
          own_address_during_emp: null,
          sponsored_by: null,
        });
    },
  },
  watch: {
    currentPageNoc: {
      async handler() {
        await this.searchNocWithFilters();
      },
    },
    "nocForm.job_abroad"(newVal) {
      this.$refs.nocFormValidation.reset();
      if (newVal === false) {
        this.selectedCountry = { code: "PK", name: "Pakistan" };
        this.selectCountry(this.selectedCountry);
      } else {
        this.selectedCountry = null;
        this.selectedState = null;
        this.selectedCity = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      hasRole: "appData/hasRole",
    }),
  },
};
</script>

<style>
.text-danger {
  color: red;
}
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
</style>
