<template>
  <div>
    <b-tabs>
      <!-- Extension of Re-employment Tab -->
      <!-- <b-tab title="Extension of Re-employment">
        <extension-of-re-employment />
      </b-tab> -->

      <!-- NOC of Job Tab -->
      <b-tab title="NOC for Job">
        <noc-for-job />
      </b-tab>
    </b-tabs>
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "@validations";
import draggable from "vuedraggable";
import util from "@/util.js";
import { heightTransition } from "@core/mixins/ui/transition";



import ExtensionOfReEmployment from '@/components/admin/ExtensionOfReEmployment.vue';
import NocForJob from '@/components/admin/NocForJob.vue';

export default {
  components: {
    draggable,
    NocForJob,
    ExtensionOfReEmployment,
  },
  mixins: [util, heightTransition],

  data() {
    return {
      
      required,
    };
  },
  async mounted() {

  },
  methods: {
    ...mapActions({
      submitExtensionRequest: "appData/submitExtensionRequest",
      submitNocRequest: "appData/submitNocRequest",
      getEmployments: "appData/getEmployments",
      getJobNocs: "appData/getJobNocs",
    }),
  },
  watch: {
    
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      hasRole: "appData/hasRole",
    }),
  },
};
</script>

<style>
.text-danger {
  color: red;
}
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
</style>
