<template>
  <div>
    <b-card>
      <validation-observer ref="extensionFormValidation">
        <b-form @submit.prevent="validateAndSubmit()">
          <b-row>
            <b-col md="4">
              <b-form-group label-for="extension-cnic">
                <label>CNIC</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="CNIC"
                  rules="required"
                >
                  <b-form-input
                    id="extension-cnic"
                    v-model="extensionForm.cnic"
                    disabled
                    :state="errors.length ? false : null"
                    placeholder="CNIC"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="extension-name">
                <label>Name</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="extension-name"
                    v-model="extensionForm.name"
                    disabled
                    :state="errors.length ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="extension-contact_no">
                <label>Mobile</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Mobile"
                  rules="required"
                >
                  <b-form-input
                    id="extension-contact_no"
                    v-model="extensionForm.contact_no"
                    disabled
                    :state="errors.length ? false : null"
                    placeholder="Mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="extension-organization">
                <label>Re-employment Organization</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Re-employment Organization"
                  :rules="{
                    required,
                    regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()_+=-]*$/,
                  }"
                >
                  <b-form-input
                    id="extension-organization"
                    v-model="extensionForm.organization"
                    :state="errors.length ? false : null"
                    placeholder="Organization"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="extension-date-of-joining">
                <label>Date of Joining (Re-employment)</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Date of Joining (Re-employment)"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="extension-date-of-joining"
                    v-model="extensionForm.date_of_joining"
                    :state="errors.length ? false : null"
                    type="date"
                    placeholder="Date of Joining"
                  /> -->
                  <b-form-datepicker
                    id="date_of_joining"
                    v-model="extensionForm.date_of_joining"
                    :state="errors.length ? false : null"
                    :max="new Date().toISOString().split('T')[0]"
                    variant="primary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="extension-struck-off-duty-date">
                <label>Struck off duty date</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Struck off duty date"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="extension-struck-off-duty-date"
                    v-model="extensionForm.struck_of_duty_date"
                    :state="errors.length ? false : null"
                    type="date"
                    placeholder="Struck off duty date"
                  /> -->
                  <b-form-datepicker
                    id="struck_of_duty_date"
                    v-model="extensionForm.struck_of_duty_date"
                    :state="errors.length ? false : null"
                    :max="new Date().toISOString().split('T')[0]"
                    variant="primary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label-for="Extension Previously Availed"
                v-slot="{ ariaDescribedby }"
              >
                <label>Extension Previously Availed</label>
                <b-form-radio-group
                  id="ExtensionPreviouslyAvailed"
                  v-model="extensionForm.extension_previously_availed"
                  :aria-describedby="ariaDescribedby"
                  class="text-black"
                >
                  <b-form-radio
                    v-model="extensionForm.extension_previously_availed"
                    :value="true"
                  >
                    Yes
                  </b-form-radio>
                  <b-form-radio
                    v-model="extensionForm.extension_previously_availed"
                    :value="false"
                  >
                    No
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="8" v-if="extensionForm.extension_previously_availed">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Previous Extention From">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Previous extension from"
                      rules="required"
                    >
                      <!-- <b-form-input
                        v-model="
                          extensionForm.extension_previously_availed_yes_from_date
                        "
                        :state="errors.length ? false : null"
                        type="date"
                        button-only
                        :max="new Date().toISOString().split('T')[0]"
                        placeholder="From"
                      /> -->
                      <b-form-datepicker
                        id="extension_previously_availed_yes_from_date"
                        v-model="
                          extensionForm.extension_previously_availed_yes_from_date
                        "
                        :state="errors.length ? false : null"
                        :max="new Date().toISOString().split('T')[0]"
                        variant="primary"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Previous Extention To">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Previous extension to"
                      rules="required"
                    >
                      <!-- <b-form-input
                        v-model="
                          extensionForm.extension_previously_availed_yes_to_date
                        "
                        :state="errors.length ? false : null"
                        :max="new Date().toISOString().split('T')[0]"
                        type="date"
                        placeholder="To"
                      /> -->

                      <b-form-datepicker
                        id="extension_previously_availed_yes_to_date"
                        v-model="
                          extensionForm.extension_previously_availed_yes_to_date
                        "
                        :state="errors.length ? false : null"
                        :max="new Date().toISOString().split('T')[0]"
                        variant="primary"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="8">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Extension Request From">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Extension Request From"
                      rules="required"
                    >
                      <!-- <b-form-input
                        v-model="extensionForm.extension_request_from_date"
                        :state="errors.length ? false : null"
                        type="date"
                        placeholder="From"
                      /> -->
                      <b-form-datepicker
                        id="extension_request_from_date"
                        v-model="extensionForm.extension_request_from_date"
                        :state="errors.length ? false : null"
                        :min="new Date().toISOString().split('T')[0]"
                        variant="primary"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Extension Request To">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Extension Request To"
                      rules="required"
                    >
                      <!-- <b-form-input
                        v-model="extensionForm.extension_request_to_date"
                        :state="errors.length ? false : null"
                        type="date"
                        placeholder="To"
                      /> -->
                      <b-form-datepicker
                        id="extension_request_to_date"
                        v-model="extensionForm.extension_request_to_date"
                        :state="errors.length ? false : null"
                        :min="new Date().toISOString().split('T')[0]"
                        variant="primary"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="Compassion">
                <label>Compassion</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="Compassion"
                  :rules="{
                    required,
                    regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]*$/,
                  }"
                >
                  <b-form-input
                    id="Compassion"
                    v-model="extensionForm.compassion"
                    :state="errors.length ? false : null"
                    placeholder="Compassion"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label-for="Organization Consent"
                v-slot="{ ariaDescribedby }"
              >
                <label>Organization Consent</label>
                <b-form-radio-group
                  id="OrganizationConsent"
                  v-model="extensionForm.org_consent"
                  :aria-describedby="ariaDescribedby"
                  class="text-black"
                >
                  <b-form-radio
                    v-model="extensionForm.org_consent"
                    :value="true"
                  >
                    Yes
                  </b-form-radio>
                  <b-form-radio
                    v-model="extensionForm.org_consent"
                    :value="false"
                  >
                    No
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1 float-right"
          >
            Submit Extension
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <b-row
        class="mt-2 mb-2"
        style="border: 1px solid #ccc; border-radius: 5px; padding: 10px"
        v-if="hasRole('op') || hasRole('su') || hasRole('op_admin')"
      >
        <b-col md="2">
          <b-form-group>
            <label> CNIC </label>
            <b-form-input
              id="cnic"
              v-model="cnic"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="CNIC"
              class="text-black"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group>
            <label> Name </label>
            <b-form-input
              id="Name"
              v-model="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Name"
              class="text-black"
            />
          </b-form-group>
        </b-col>
        <b-col class="text-right mb-1" style="margin: auto">
          <div style="display: flex; justify-content: flex-end">
            <b-button
              variant="primary"
              pill
              style="margin-right: 2%"
              @click="searchEmploymentViaSearchButton"
            >
              <feather-icon icon="SearchIcon" class="mr-50" />
              Search
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-card-body class="mt-2">
        <b-table
          responsive="sm"
          :fields="fields_employment"
          :paginated="true"
          :items="employments"
          details-td-class="m-0 p-0"
          :tbody-tr-class="rowClass"
          sticky-header="700px"
          small
          class="text-black"
        >
          <template #cell(is_received)="row">
            <span>
              {{ row.item.is_received ? "Yes" : "No" }}
            </span>
          </template>
          <template #cell(status)="row">
            <span>
              {{ getNocStatusByValue(row.item.status) }}
            </span>
          </template>
        </b-table>

        <b-row>
          <b-col md="8">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDataLength"
              :per-page="per_page"
            />
          </b-col>
          <b-col md="4">
            <div style="float: right">
              <h5 style="margin: 0; display: inline" class="text-primary">
                Count:
              </h5>
              <h5 style="margin: 0; display: inline" align="right">
                <strong>
                  {{ totalDataLength }}
                </strong>
              </h5>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import util from "@/util.js";
import { heightTransition } from "@core/mixins/ui/transition";
import { getAllCountries } from "countries-and-timezones";
import { Country, State, City } from "country-state-city";

// Custom rule to check if date_of_joining is before struck_off_duty_date

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    VueSelectPaginated,
    VuePerfectScrollbar,
    draggable,
    Country,
    State,
    City,
  },
  mixins: [util, heightTransition],

  data() {
    return {
      name: null,
      cnic: null,
      totalDataLength: 0,
      per_page: 0,
      currentPage: 1,

      employments: [],
      extensionForm: {
        cnic: "",
        name: "",
        struck_of_duty_date: "",
        contact_no: "",
        organization: "",
        date_of_joining: "",
        extension_previously_availed: false,
        extension_previously_availed_yes_from_date: null,
        extension_previously_availed_yes_to_date: null,
        extension_request_from_date: "",
        extension_request_to_date: "",
        compassion: "",
        org_consent: false,
      },

      show: false,
      fields_employment: [
        { key: "cnic", label: "CNIC", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "status", label: "status", sortable: true },
        { key: "is_received", label: "Received", sortable: true },
        { key: "contact_no", label: "Contact", sortable: true },
        { key: "created_at", label: "Request Date", sortable: true },
      ],

      required,
    };
  },
  async mounted() {
    await this.getEmploymentData();
    const userData = await this.getLoggedInUser;
    this.extensionForm.cnic = userData.cnic;
    this.extensionForm.name = userData.name;
    this.extensionForm.contact_no = userData.mobile;
  },
  methods: {
    ...mapActions({
      submitExtensionRequest: "appData/submitExtensionRequest",
      getEmployments: "appData/getEmployments",
    }),
    async searchEmploymentViaSearchButton() {
      this.currentPage = 1;
      await this.searchEmploymentWithFilters();
    },

    async searchEmploymentWithFilters() {
      let payload = {};
      payload["pageNumber"] = this.currentPage;
      if (this.name !== null) {
        payload["name"] = this.name;
      }
      if (this.cnic !== null) {
        payload["cnic"] = this.cnic;
      }
      await this.getEmploymentData(payload);
    },

    async getEmploymentData(searchParams = {}) {
      try {
        this.show = true;
        const res = await this.getEmployments(searchParams);
        this.employments = res.data.results;
        this.per_page = res.data.per_page;
        this.totalDataLength = res.data.count;
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    validateDateOfJoining() {
      const joiningDate = new Date(this.extensionForm.date_of_joining);
      const struckOffDate = new Date(this.extensionForm.struck_of_duty_date);
      if (joiningDate >= struckOffDate) {
        console.log("Here");
        this.$refs.extensionFormValidation.setErrors({
          "Struck off duty date": [
            "Struck Off Date must be after Date of Joining",
          ],
        });
        return false;
      }
      return true;
    },
    validatePreviousRequestDates() {
      if (this.extensionForm.extension_previously_availed) {
        const previousFromDate = new Date(
          this.extensionForm.extension_previously_availed_yes_from_date
        );
        const previousToDate = new Date(
          this.extensionForm.extension_previously_availed_yes_to_date
        );
        const extensionFromDate = new Date(
          this.extensionForm.extension_request_from_date
        );
        const extensionToDate = new Date(
          this.extensionForm.extension_request_to_date
        );
        const joiningDate = new Date(this.extensionForm.date_of_joining);

        if (previousFromDate >= previousToDate) {
          this.$refs.extensionFormValidation.setErrors({
            "Previous extension to": [
              "Previous extension to must be after previous extension from date",
            ],
          });
          return false;
        }
        if (previousFromDate <= joiningDate || previousToDate <= joiningDate) {
          this.$refs.extensionFormValidation.setErrors({
            "Previous extension from": [
              "Previous extension to must be after joining date",
            ],
            "Previous extension to": [
              "Previous extension to must be after joining date",
            ],
          });
          return false;
        }
      }
      return true;
    },
    validateExtensionRequestDates() {
      const extensionFromDate = new Date(
        this.extensionForm.extension_request_from_date
      );
      const extensionToDate = new Date(
        this.extensionForm.extension_request_to_date
      );
      if (extensionFromDate >= extensionToDate) {
        this.$refs.extensionFormValidation.setErrors({
          "Extension Request To": [
            "Extension Request To must be after Extension Request From",
          ],
        });
        return false;
      }
      return true;
    },

    async validateAndSubmit() {
      if (!this.validateDateOfJoining() || !this.validatePreviousRequestDates() || ! this.validateExtensionRequestDates()) {
        return;
      }
      const isValid = await this.$refs.extensionFormValidation.validate();
      if (isValid) {
        await this.submitExtensionForm();
        await this.getEmploymentData();
        this.$refs.extensionFormValidation.reset();
        this.reset();
      }
    },
    async submitExtensionForm() {
      this.show = true;
      try {
        const res = await this.submitExtensionRequest({
          cnic: this.extensionForm.cnic,
          name: this.extensionForm.name,
          struck_of_duty_date: this.extensionForm.struck_of_duty_date,
          contact_no: this.extensionForm.contact_no,
          organization: this.extensionForm.organization,
          date_of_joining: this.extensionForm.date_of_joining,
          extension_previously_availed:
            this.extensionForm.extension_previously_availed,
          extension_previously_availed_yes_from_date:
            this.extensionForm.extension_previously_availed_yes_from_date,
          extension_previously_availed_yes_to_date:
            this.extensionForm.extension_previously_availed_yes_to_date,
          extension_request_from_date:
            this.extensionForm.extension_request_from_date,
          extension_request_to_date:
            this.extensionForm.extension_request_to_date,
          compassion: this.extensionForm.compassion,
          org_consent: this.extensionForm.org_consent,
          updated_by: this.getLoggedInUser.id,
          created_by: this.getLoggedInUser.id,
        });

        if (res.status === 201) {
          this.displaySuccess("Your request has successfully been submitted");
          this.show = false;
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },

    async reset() {
      this.extensionForm = {
        cnic: this.getLoggedInUser.cnic,
        name: this.getLoggedInUser.name,
        contact_no: this.getLoggedInUser.mobile,
        struck_of_duty_date: "",
        organization: "",
        date_of_joining: "",
        extension_previously_availed: false,
        extension_previously_availed_yes_from_date: null,
        extension_previously_availed_yes_to_date: null,
        extension_request_from_date: "",
        extension_request_to_date: "",
        compassion: "",
        org_consent: false,
      };
    },
  },
  watch: {
    currentPage: {
      async handler() {
        await this.searchEmploymentWithFilters();
      },
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      hasRole: "appData/hasRole",
    }),
  },
};
</script>

<style>
.text-danger {
  color: red;
}
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
</style>
